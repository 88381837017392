import { useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';

import { Flex } from '@raystack/apsara';
import useSWR from 'swr';

import { apiClient } from '@src/clients';
import { Header } from '@src/components/Custom/Header';
import { EmptyState } from '@src/components/EmptyState';
import { Show } from '@src/hoc';
import { useOutsideClick } from '@src/hooks/useOutsideClick';
import { ReactComponent as BellIcon } from '@src/icons/bell-2.svg';
import { closeNotifications } from '@src/redux/_reducers/appReducer';

import Filter, { FilterValues, filterMessages } from './filter';
import NotificationItem from './item';

import styles from './styles.module.css';

function Loader() {
  return [...new Array(3)].map((_, i) => (
    <Flex
      className={styles.noticationItem}
      gap={'medium'}
      key={i}
      data-loading='true'
    >
      <Skeleton width={'32px'} height={'32px'} />
      <Flex direction={'column'} className={styles.flex1} gap={'small'}>
        <Skeleton containerClassName={styles.flex1} count={2} />
        <Skeleton containerClassName={styles.flex1} width={'50%'} />
      </Flex>
    </Flex>
  ));
}

interface NotificationsProps {
  notificationIconRef: React.MutableRefObject<HTMLElement>;
}

export default function Notifications({
  notificationIconRef
}: NotificationsProps) {
  const ref = useRef(null);
  const dispatch = useDispatch();

  const [currentFilter, setCurrentFilter] = useState<FilterValues>('All');

  const { data, isLoading } = useSWR(
    '/feed',
    apiClient.beacon.apiUserSelfFeedList,
    {
      revalidateOnMount: true,
      refreshInterval: 1000
    }
  );

  const messages = data?.data?.messages || [];

  useOutsideClick({
    triggerRef: notificationIconRef,
    contentRef: ref,
    callback: () => dispatch(closeNotifications())
  });

  const unreadCount = messages.reduce((acc: number, msg) => {
    if (msg.status === 'unread') {
      acc = acc + 1;
    }
    return acc;
  }, 0);

  const filteredMessages = filterMessages(messages, currentFilter);

  return (
    <div className={styles.panel} ref={ref}>
      <Header hasSeparator={false} className={styles.header}>
        <Header.Text size='medium'>All Notifications</Header.Text>
      </Header>

      <Flex direction={'column'} className={styles.noticationPanelContent}>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Filter
              unreadCount={unreadCount}
              value={currentFilter}
              setValue={setCurrentFilter}
            />
            <Show
              when={filteredMessages.length > 0}
              fallback={
                <Flex style={{ padding: '32px 16px' }}>
                  <EmptyState
                    headerText='Nothing to show here'
                    subHeaderText="When you have new notifications, they'll appear here. Stay tuned!"
                    icon={<BellIcon />}
                  />
                </Flex>
              }
            >
              <div className={styles.messageList}>
                {filteredMessages.map(msg => (
                  <NotificationItem message={msg} key={msg.message_id} />
                ))}
              </div>
            </Show>
          </>
        )}
      </Flex>
    </div>
  );
}
