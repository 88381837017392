/* eslint-disable */

/* tslint:disable */

/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
import type {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  HeadersDefaults,
  ResponseType
} from 'axios';
import axios from 'axios';

export interface ApiError {
  code?: string;
  details?: string;
  message?: string;
}

export interface ApiErrorResponse {
  error?: ApiError;
}

export interface BandsetReadListResponse {
  bandsets?: BandsetReadResponse[];
}

export interface BandsetReadResponse {
  bands?: GithubComPixxelhqClerkApiBandsetBand[];
  /** @example 1 */
  captures?: number;
  /** @example "" */
  created_at?: string;
  /** @example "" */
  created_by?: string;
  /** @example "Agriculture bandset" */
  description?: string;
  /** @example "47435e2b-d8c4-41ff-9de9-2be3bfc92276" */
  id?: string;
  /** @example "Agriculture" */
  name?: string;
  /** @example "" */
  updated_at?: string;
  /** @example "" */
  updated_by?: string;
}

export interface BandsetRequest {
  bands: GithubComPixxelhqClerkApiBandsetBand[];
  /** @example 1 */
  captures: number;
  /** @example "Agriculture bandset" */
  description: string;
  /** @example "Agriculture" */
  name: string;
}

export interface CatalogCatalogRequest {
  /** @example "TD1_006800_20230528_L2A_20230615_03001067" */
  image_id: string;
  /** @example "e31ab6f8-d359-4c6a-83c6-bfa32229bb01" */
  org_id: string;
}

export interface CatalogCreateCatalogRequest {
  catalogs?: CatalogCatalogRequest[];
}

export interface CatalogDownloadRequest {
  /** @uniqueItems true */
  catalog_ids: string[];
}

export interface CatalogDownloadResponse {
  id?: string;
  signed_url?: string;
  status?: ConstantDownloadStatus;
}

export interface CatalogReadListResponse {
  catalogs?: CatalogReadResponse[];
}

export interface CatalogReadResponse {
  /** @example 100 */
  area?: number;
  /** @example "2024-05-27T06:50:20.056549Z" */
  created_at?: string;
  /** @example "e31ab6f8-d359-4c6a-83c6-bfa32229bb01" */
  created_by?: string;
  /** @example "SUCCESSFUL" */
  delivery_status?: ConstantDeliveryStatus;
  details?: CosmosItem;
  /** @example "47435e2b-d8c4-41ff-9de9-2be3bfc92276" */
  id?: string;
  /** @example "TD1_006800_20230528_L2A_20230615_03001067" */
  image_id?: string;
  /** @example "e31ab6f8-d359-4c6a-83c6-bfa32229bb01" */
  org_id?: string;
  /** @example 10 */
  overlap?: number;
  /** @example "e31ab6f8-d359-4c6a-83c6-bfa32229bb01" */
  task_id?: string;
  /** @example "ARCHIVE" */
  type?: ConstantCatalogType;
  /** @example "2024-05-27T06:50:20.056549Z" */
  updated_at?: string;
  /** @example "e31ab6f8-d359-4c6a-83c6-bfa32229bb01" */
  updated_by?: string;
}

export enum ConstantCatalogType {
  CatalogTypeArchive = 'ARCHIVE',
  CatalogTypeTasking = 'TASKING'
}

export enum ConstantDeliveryStatus {
  DeliveryStatusUnspecified = 'UNSPECIFIED',
  DeliveryStatusCreated = 'CREATED',
  DeliveryStatusFailed = 'FAILED',
  DeliveryStatusInProgress = 'IN_PROGRESS',
  DeliveryStatusSuccess = 'SUCCESS'
}

export enum ConstantDownloadStatus {
  DownloadStatusSuccess = 'success',
  DownloadStatusCreated = 'created',
  DownloadStatusFailed = 'failed',
  DownloadStatusInProgress = 'in_progress'
}

export enum ConstantOrderItemCaptureSpeed {
  OrderItemCaptureSpeedStandard = 'STANDARD',
  OrderItemCaptureSpeedPriority = 'PRIORITY',
  OrderItemCaptureSpeedUrgent = 'URGENT',
  OrderItemCaptureSpeedAssured = 'ASSURED'
}

export enum ConstantOrderItemDeliverySpeed {
  OrderItemDeliverySpeedStandard = 'STANDARD',
  OrderItemDeliverySpeedExpedited = 'EXPEDITED',
  OrderItemDeliverySpeedSuperExpedited = 'SUPER_EXPEDITED'
}

export enum ConstantOrderItemFeasibilityStatus {
  OrderItemFeasibilityStatusNotApplicable = 'NOT_APPLICABLE',
  OrderItemFeasibilityStatusFailed = 'FAILED',
  OrderItemFeasibilityStatusWeakSuccess = 'WEAK_SUCCESS',
  OrderItemFeasibilityStatusSuccess = 'SUCCESS'
}

export enum ConstantOrderItemImageType {
  OrderItemImageTypeVNIR = 'VNIR',
  OrderItemImageTypeSWIR = 'SWIR',
  OrderItemImageTypeVSWIR = 'VSWIR'
}

export enum ConstantOrderItemPaymentMode {
  OrderItemPaymentModeFull = 'FULL',
  OrderItemPaymentModeMonthly = 'MONTHLY'
}

export enum ConstantOrderItemPaymentStatus {
  OrderItemPaymentStatusPaid = 'PAID',
  OrderItemPaymentStatusPartialRefund = 'PARTIAL-REFUND',
  OrderItemPaymentStatusRefund = 'REFUND',
  OrderItemPaymentStatusUnpaid = 'UNPAID',
  OrderItemPaymentStatusPartialMonthly = 'PARTIAL-MONTHLY'
}

export enum ConstantOrderItemRecurrence {
  OrderItemRecurrenceOnce = 'ONCE',
  OrderItemRecurrenceWeekly = 'WEEKLY',
  OrderItemRecurrenceBiweekly = 'BIWEEKLY',
  OrderItemRecurrenceMonthly = 'MONTHLY',
  OrderItemRecurrenceBimonthly = 'BIMONTHLY',
  OrderItemRecurrenceQuarterly = 'QUARTERLY'
}

export enum ConstantOrderItemStatus {
  OrderItemStatusDraft = 'DRAFT',
  OrderItemStatusActive = 'ACTIVE',
  OrderItemStatusCompleted = 'COMPLETED',
  OrderItemStatusCancelled = 'CANCELLED',
  OrderItemStatusFailed = 'FAILED',
  OrderItemStatusPartiallyCompleted = 'PARTIALLY_COMPLETED'
}

export enum ConstantOrderItemType {
  OrderItemTypeTasking = 'TASKING',
  OrderItemTypeArchive = 'ARCHIVE',
  OrderItemTypeRecurring = 'RECURRING'
}

export enum ConstantOrderStatus {
  OrderStatusPaid = 'PAID',
  OrderStatusRefund = 'REFUND',
  OrderStatusUnpaid = 'UNPAID',
  OrderStatusPaymentInProgress = 'PAYMENT_IN_PROGRESS'
}

export enum ConstantOrganizationAdoption {
  OrganizationAdoptionEarly = 'early',
  OrganizationAdoptionEarlyBinding = 'early_binding',
  OrganizationAdoptionNonEarly = 'non_early'
}

export enum ConstantOrganizationType {
  OrganizationTypeCommercialSingleEntity = 'commercial_single_entity',
  OrganizationTypeCommercialMultiEntity = 'commercial_multi_entity',
  OrganizationTypeGovernmentStateFed = 'government_state_fed',
  OrganizationTypeGovernmentCivil = 'government_civil',
  OrganizationTypeGovernmentAllGov = 'government_all_gov',
  OrganizationTypeGovernmentPublicAccess = 'government_public_access',
  OrganizationTypeNonCommercialFreeTrial = 'non_commercial_free_trial',
  OrganizationTypeNonCommercialAcademicEnterprise = 'non_commercial_academic_enterprise',
  OrganizationTypeNonCommercialAcademicTrial = 'non_commercial_academic_trial',
  OrganizationTypeNotAvailable = ''
}

export enum ConstantSortingOrder {
  AscendingSortingOrder = 'ASC',
  DescendingSortingOrder = 'DESC'
}

export enum ConstantStripStatus {
  StripStatusUnspecified = 'UNSPECIFIED',
  StripStatusPending = 'PENDING',
  StripStatusScheduled = 'SCHEDULED',
  StripStatusCaptured = 'CAPTURED',
  StripStatusCatalogued = 'CATALOGUED',
  StripStatusFailed = 'FAILED'
}

export enum ConstantTaskStatus {
  TaskStatusDraft = 'DRAFT',
  TaskStatusRequested = 'REQUESTED',
  TaskStatusCreated = 'CREATED',
  TaskStatusInProgress = 'IN_PROGRESS',
  TaskStatusFulfilled = 'FULFILLED',
  TaskStatusRejected = 'REJECTED',
  TaskStatusPartiallyFulfilled = 'PARTIALLY_FULFILLED',
  TaskStatusFailed = 'FAILED',
  TaskStatusCanceled = 'CANCELED',
  TaskStatusExpired = 'EXPIRED'
}

export enum ConstantTaskType {
  TaskTypeArchive = 'ARCHIVE',
  TaskTypeTasking = 'TASKING'
}

export enum ConstantTraceStatus {
  TraceStatusDelivered = 'DELIVERED',
  TraceStatusDraft = 'DRAFT',
  TraceStatusCreated = 'CREATED',
  TraceStatusPending = 'PENDING',
  TraceStatusScheduled = 'SCHEDULED',
  TraceStatusActive = 'ACTIVE',
  TraceStatusFailed = 'FAILED',
  TraceStatusPartiallyFulfilled = 'PARTIALLY_FULFILLED',
  TraceStatusFulfilled = 'FULFILLED',
  TraceStatusInProgress = 'IN_PROGRESS',
  TraceStatusUnspecified = 'UNSPECIFIED',
  TraceStatusCompleted = 'COMPLETED',
  TraceStatusCancelled = 'CANCELLED',
  TraceStatusExpired = 'EXPIRED'
}

export enum ConstantTraceType {
  TraceTypeOrderItem = 'order_item',
  TraceTypeTask = 'task',
  TraceTypeStrip = 'strip',
  TraceTypeCatalog = 'catalog',
  TraceTypeDelivery = 'delivery'
}

export interface CosmosAsset {
  'eo:bands'?: CosmosEoBand[];
  href?: string;
  roles?: string[];
  title?: string;
  type?: string;
}

export interface CosmosEoBand {
  center_wavelength?: number;
  common_name?: string;
  fwhm?: number;
  name?: string;
}

export interface CosmosGeometry {
  coordinates?: number[][][];
  type?: string;
}

export interface CosmosItem {
  assets?: Record<string, CosmosAsset>;
  bbox?: number[];
  collection?: string;
  geometry?: CosmosGeometry;
  id?: string;
  links?: CosmosLink[];
  properties?: CosmosProperties;
  stac_extensions?: any[];
  stac_version?: string;
  type?: string;
}

export interface CosmosLink {
  href?: string;
  rel?: string;
  title?: string;
  type?: string;
}

export interface CosmosProperties {
  constellation?: string;
  created?: string;
  datetime?: string;
  'eo:cloud_cover'?: number;
  gsd?: number;
  instruments?: string[];
  platform?: string;
  'proj:epsg'?: number;
  'proj:shape'?: number[];
  'proj:transform'?: number[];
  'proj:wkt2'?: string;
  'sat:orbit_state'?: string;
  'sat:relative_orbit'?: number;
  'sci:doi'?: string;
  updated?: string;
  'view:off_nadir'?: number;
  'view:satellite_look_angle'?: number;
  'view:sun_azimuth'?: number;
  'view:sun_elevation'?: number;
}

export interface DeliveryCloudDeliveryRequest {
  config: string;
  path?: string;
}

export interface DeliveryCloudDeliveryResponse {
  /** @example "config" */
  config?: string;
  /** @example "path" */
  path?: string;
}

export interface DeliveryCreateDeliveryRequest {
  /** @example ["[\"550e8400-e29b-41d4-a716-446655440000\""," \"6ba7b810-9dad-11d1-80b4-00c04fd430c8\"]"] */
  catalog_ids: string[];
  cloud_delivery: DeliveryCloudDeliveryRequest;
}

export interface DeliveryReadListResponse {
  deliveries?: DeliveryReadResponse[];
}

export interface DeliveryReadResponse {
  /** @example "47435e2b-d8c4-51ff-9de9-2be3bfc92276" */
  catalog_id?: string;
  cloud_delivery?: DeliveryCloudDeliveryResponse;
  /** @example "2024-05-27T06:50:20.056549Z" */
  created_at?: string;
  /** @example "e31ab6f8-d359-4c6a-83c6-bfa32229bb01" */
  created_by?: string;
  /** @example "47435e2b-d8c4-41ff-9de9-2be3cfc92276" */
  delivery_id?: string;
  /** @example "47435e2b-d8c4-41ff-9de9-2be3bfc92276" */
  id?: string;
  /** @example "SUCCESS" */
  status?: ConstantDeliveryStatus;
  /** @example "e31ab6f8-d359-4c6a-83c6-bfa32229bb01" */
  task_id?: string;
  /** @example "2024-05-27T06:50:20.056549Z" */
  updated_at?: string;
  /** @example "e31ab6f8-d359-4c6a-83c6-bfa32229bb01" */
  updated_by?: string;
}

export interface DeliveryUpdateDeliveryRequest {
  /** @example "SUCCESS" */
  status?: 'UNSPECIFIED' | 'CREATED' | 'FAILED' | 'IN_PROGRESS' | 'SUCCESS';
}

export interface GeojsonFeature {
  geometry?: GeojsonFeatureGeometry;
  properties?: object;
  type?: string;
}

export interface GeojsonFeatureGeometry {
  coordinates?: number[][][];
  type?: string;
}

export interface GeojsonGeometry {
  features?: GeojsonFeature[];
  type?: string;
}

export interface GithubComPixxelhqClerkApiBandsetBand {
  name: string;
  wavelength: number;
}

export interface GithubComPixxelhqClerkApiCatalogCatalogFilter {
  bbox?: number[];
  collections?: string[];
  datetime?: string;
  ids?: string[];
  image_id?: string[];
  intersects?: GithubComPixxelhqClerkApiCatalogGeometry;
  /** @default 1000 */
  limit?: number;
  /** @min 0 */
  offset?: number;
  org_id?: string;
  query?: Record<string, Record<string, any>>;
  sortby?: Record<string, ConstantSortingOrder>[];
  task_id?: string;
}

export interface GithubComPixxelhqClerkApiCatalogGeometry {
  coordinates?: number[][][];
  type?: string;
}

export interface GithubComPixxelhqClerkApiOrderItemBand {
  name?: string;
  wavelength?: number;
}

export interface GithubComPixxelhqClerkApiOrderItemBandset {
  bands?: GithubComPixxelhqClerkApiOrderItemBand[];
  bandset_id?: string;
  custom?: number[][];
}

export interface GithubComPixxelhqClerkApiOrderItemImage {
  geometry?: GeojsonGeometry;
  image_id?: string;
}

export interface GithubComPixxelhqClerkApiOrderItemUplift {
  amount?: number;
  percentage?: number;
  subtitle?: string;
  title?: string;
}

export interface OrderCreateRequest {
  /** @example ["['47435e2b-d8c4-41ff-9de9-2be3bfc92276']"] */
  order_item_ids: string[];
  /** @example ["['s3://payment.pdf']"] */
  payment_docs?: string[];
}

export interface OrderReadListResponse {
  orders?: OrderReadResponse[];
}

export interface OrderReadResponse {
  /** @example "" */
  created_at?: string;
  /** @example "" */
  created_by?: string;
  /** @example 200 */
  discount_amount?: number;
  /** @example "NEW_USER" */
  discount_code?: string;
  /** @example 800 */
  final_amount?: number;
  /** @example "47435e2b-d8c4-41ff-9de9-2be3bfc92276" */
  id?: string;
  /** @example "e31ab6f8-d359-4c6a-83c6-bfa32229bb01" */
  org_id?: string;
  /** @example "" */
  paid_at?: string;
  /** @example ["['s3://payment.pdf']"] */
  payment_docs?: string[];
  /** @example 200 */
  refund_amount?: number;
  /** @example "" */
  refund_at?: string;
  /** @example "PAID" */
  status?: ConstantOrderStatus;
  /** @example 1000 */
  total_amount?: number;
  /** @example "e31ab6f8-d359-4c6a-83c6-bfa32229bb01" */
  transaction_id?: string;
  /** @example "" */
  updated_at?: string;
  /** @example "" */
  updated_by?: string;
  /** @example "e31ab6f8-d359-4c6a-83c6-bfa32449bb01" */
  user_id?: string;
}

export interface OrderUpdateRequest {
  /** @example ["['s3://payment.pdf']"] */
  payment_docs?: string[];
  /** @example "PAYMENT_IN_PROGRESS" */
  status?: 'PAID' | 'REFUND' | 'UNPAID' | 'PAYMENT_IN_PROGRESS';
}

export interface OrderitemCreateOrderItemRequest {
  bandset?: {
    bandset_id?: string;
    custom?: number[][];
  };
  /** @example "STANDARD/PRIORITY/URGENT/ASSURED" */
  capture_speed?:
    | 'STANDARD'
    | 'PRIORITY'
    | 'URGENT'
    | 'ASSURED'
    | 'NOT_APPLICABLE';
  /** @example 0 */
  cloud_cover?: number;
  cloud_delivery?: {
    config?: string;
    path?: string;
  };
  /** @example "STANDARD/EXPEDITED/SUPER_EXPEDITED" */
  delivery_speed?:
    | 'STANDARD'
    | 'EXPEDITED'
    | 'SUPER_EXPEDITED'
    | 'NOT_APPLICABLE';
  /** @example "2021-07-01" */
  end_date?: string;
  /** @example "VNIR" */
  image_type?: ConstantOrderItemImageType;
  images: {
    geometry?: GeojsonGeometry;
    image_id?: string;
  }[];
  /**
   * @maxLength 100
   * @example "Order Item 1"
   */
  name?: string;
  /** @example 0 */
  off_nadir?: number;
  /** @example "FULL" */
  payment_mode?: 'FULL' | 'MONTHLY';
  /** @example "ONCE/WEEKLY/BIWEEKLY/MONTHLY/BIMONTHLY/QUARTERLY" */
  recurrence?:
    | 'ONCE'
    | 'WEEKLY'
    | 'BIWEEKLY'
    | 'MONTHLY'
    | 'BIMONTHLY'
    | 'QUARTERLY'
    | 'NOT_APPLICABLE';
  /** @example "2021-07-01" */
  start_date?: string;
  /** @example "TASKING/ARCHIVE/RECURRING" */
  type?: 'TASKING' | 'ARCHIVE' | 'RECURRING';
  /** @example "agriculture" */
  usecase: string;
}

export interface OrderitemReadListResponse {
  order_items?: OrderitemReadResponse[];
}

export interface OrderitemReadResponse {
  /** @example 1000 */
  area?: number;
  bandset?: GithubComPixxelhqClerkApiOrderItemBandset;
  /** @example 10000 */
  base_amount?: number;
  /** @example "STANDARD/PRIORITY/URGENT/ASSURED" */
  capture_speed?: ConstantOrderItemCaptureSpeed;
  /** @example 0 */
  cloud_cover?: number;
  cloud_delivery?: {
    config?: string;
    path?: string;
  };
  /** @example "2024-05-27T06:50:20.056549Z" */
  created_at?: string;
  /** @example "e31ab6f8-d359-4c6a-83c6-bfa32229bb01" */
  created_by?: string;
  /** @example "STANDARD/EXPEDITED/SUPER_EXPEDITED" */
  delivery_speed?: ConstantOrderItemDeliverySpeed;
  /** @example "2021-07-01" */
  end_date?: string;
  /** @example "FAILED/WEAK_SUCCESS/SUCCESS" */
  feasibility_status?: ConstantOrderItemFeasibilityStatus;
  /** @example 12000 */
  final_amount?: number;
  /** @example "47435e2b-d8c4-41ff-9de9-2be3bfc92276" */
  id?: string;
  /** @example "VNIR" */
  image_type?: ConstantOrderItemImageType;
  images?: GithubComPixxelhqClerkApiOrderItemImage[];
  /** @example false */
  in_cart?: boolean;
  /** @example 0 */
  no_of_occurences?: number;
  /** @example 2 */
  number_of_bands?: number;
  /** @example 0 */
  off_nadir?: number;
  /** @example "47435e2b-d8c4-41ff-9de9-2be3bfc92276" */
  order_id?: string;
  /** @example "47435e2b-d8c4-41ff-9de9-2be3bfc92276" */
  org_id?: string;
  /** @example "FULL" */
  payment_mode?: ConstantOrderItemPaymentMode;
  /** @example "PAID/PARTIAL-REFUND/REFUND/UNPAID/PARTIAL-MONTHLY" */
  payment_status?: ConstantOrderItemPaymentStatus;
  /** @example "ONCE/WEEKLY/BIWEEKLY/MONTHLY/BIMONTHLY/QUARTERLY" */
  recurrence?: ConstantOrderItemRecurrence;
  /** @example "2021-07-01" */
  start_date?: string;
  /** @example "DRAFT/ACTIVE/COMPLETED/CANCELLED/FAILED/PARTIALLY_COMPLETED" */
  status?: ConstantOrderItemStatus;
  /** @example 100 */
  tokens_used?: number;
  /** @example "47435e2b-d8c4-41ff-9de9-2be3bfc92276" */
  transaction_id?: string;
  /** @example "TASKING/ARCHIVE/RECURRING" */
  type?: ConstantOrderItemType;
  /** @example "2024-05-27T06:50:20.056549Z" */
  updated_at?: string;
  /** @example "e31ab6f8-d359-4c6a-83c6-bfa32229bb01" */
  updated_by?: string;
  uplifts?: GithubComPixxelhqClerkApiOrderItemUplift[];
  /** @example "agriculture" */
  usecase?: string;
  /** @example "47435e2b-d8c4-41ff-9de9-2be3bfc92276" */
  user_id?: string;
}

export interface OrderitemUpdateOrderItemRequest {
  bandset?: {
    bandset_id?: string;
    custom?: number[][];
  };
  /** @example "STANDARD/PRIORITY/URGENT/ASSURED" */
  capture_speed?:
    | 'STANDARD'
    | 'PRIORITY'
    | 'URGENT'
    | 'ASSURED'
    | 'NOT_APPLICABLE';
  /** @example 0 */
  cloud_cover?: number;
  cloud_delivery?: {
    config?: string;
    path?: string;
  };
  /** @example "STANDARD/EXPEDITED/SUPER_EXPEDITED" */
  delivery_speed?:
    | 'STANDARD'
    | 'EXPEDITED'
    | 'SUPER_EXPEDITED'
    | 'NOT_APPLICABLE';
  /** @example "2021-07-01" */
  end_date?: string;
  id?: string;
  /** @example "VNIR" */
  image_type?: ConstantOrderItemImageType;
  images: {
    geometry?: GeojsonGeometry;
    image_id?: string;
  }[];
  /** @example false */
  in_cart?: boolean;
  /**
   * @maxLength 100
   * @example "Order Item 1"
   */
  name?: string;
  /** @example 0 */
  off_nadir?: number;
  /** @example "FULL" */
  payment_mode?: 'FULL' | 'MONTHLY';
  /** @example "ONCE/WEEKLY/BIWEEKLY/MONTHLY/BIMONTHLY/QUARTERLY" */
  recurrence?:
    | 'ONCE'
    | 'WEEKLY'
    | 'BIWEEKLY'
    | 'MONTHLY'
    | 'BIMONTHLY'
    | 'QUARTERLY'
    | 'NOT_APPLICABLE';
  /** @example "2021-07-01" */
  start_date?: string;
  /** @example "TASKING/ARCHIVE/RECURRING" */
  type?: 'TASKING' | 'ARCHIVE' | 'RECURRING';
  /** @example "agriculture" */
  usecase: string;
}

export interface OrganizationCreateRequest {
  /** @default "non_early" */
  adoption?: 'early' | 'early_binding' | 'non_early';
  /** @default false */
  kyc_successful?: boolean;
  license_type?:
    | 'commercial_single_entity'
    | 'commercial_multi_entity'
    | 'government_state_fed'
    | 'government_civil'
    | 'government_all_gov'
    | 'government_public_access'
    | 'non_commercial_free_trial'
    | 'non_commercial_academic_enterprise'
    | 'non_commercial_academic_trial';
  org_id: string;
}

export interface OrganizationReadListResponse {
  organizations?: OrganizationReadResponse[];
  total?: number;
}

export interface OrganizationReadResponse {
  adoption?: ConstantOrganizationAdoption;
  avatar?: string;
  created_at?: string;
  created_by?: string;
  id?: string;
  kyc_successful?: boolean;
  license_type?: ConstantOrganizationType;
  /** from frontier */
  name?: string;
  org_id?: string;
  state?: string;
  title?: string;
  updated_at?: string;
  updated_by?: string;
}

export interface OrganizationUpdateRequest {
  /** @default "non_early" */
  adoption?: 'early' | 'early_binding' | 'non_early';
  /** @default false */
  kyc_successful?: boolean;
  license_type?:
    | 'commercial_single_entity'
    | 'commercial_multi_entity'
    | 'government_state_fed'
    | 'government_civil'
    | 'government_all_gov'
    | 'government_public_access'
    | 'non_commercial_free_trial'
    | 'non_commercial_academic_enterprise'
    | 'non_commercial_academic_trial';
}

export interface PermissionCreatePermissionResponse {
  permissions?: PermissionPermission[];
}

export interface PermissionListPermissionResponse {
  permissions?: PermissionPermission[];
}

export interface PermissionPermission {
  /** @example "" */
  created_at?: string;
  /** @example "47435e2b-d8c4-41ff-9de9-2be3bfc92276" */
  id: string;
  /** @example "TD1_006800_20230528_L2A_20230615_03001067" */
  image_id: string;
  /** @example "e31ab6f8-d359-4c6a-83c6-bfa32229bb01" */
  org_id: string;
  /** @example "" */
  updated_at?: string;
}

export interface StripReadListResponse {
  strips?: StripReadResponse[];
}

export interface StripReadResponse {
  /** @example "" */
  assets_url?: string;
  /** @example "" */
  created_at?: string;
  /** @example "" */
  created_by?: string;
  /** @example "2021-07-01T00:00:00Z" */
  datetime?: string;
  /** @example "47435e2b-d8c4-41ff-9de9-2be3bfc92276" */
  id?: string;
  /** @example "TD1_xxx" */
  image_id?: string;
  /** @example "pixxel-td2" */
  satellite_id?: string;
  /** @example "SCHEDULED" */
  status?: ConstantStripStatus;
  /** @example "" */
  strip_id?: string;
  /** @example "" */
  task_id?: string;
  /** @example "" */
  updated_at?: string;
  /** @example "" */
  updated_by?: string;
}

export interface StripUpdateStripByImageIDRequest {
  /** @example "TD1_xxx" */
  image_id: string;
  /** @example "IMAGE_STATUS_SUCCESS" */
  status:
    | 'IMAGE_STATUS_SUCCESS'
    | 'IMAGE_STATUS_FAILED'
    | 'IMAGE_STATUS_UNSPECIFIED';
}

export interface StripUpdateStripRequest {
  /** @example "" */
  assets_url?: string;
  /** @example "TD1_xxx" */
  image_id: string;
  /** @example "STRIP_STATUS_UNSPECIFIED" */
  status:
    | 'STRIP_STATUS_UNSPECIFIED'
    | 'STRIP_STATUS_PENDING'
    | 'STRIP_STATUS_SCHEDULED'
    | 'STRIP_STATUS_CAPTURED'
    | 'STRIP_STATUS_CATALOGUED'
    | 'STRIP_STATUS_FAILED';
}

export interface TaskReadListResponse {
  tasks?: TaskReadResponse[];
}

export interface TaskReadResponse {
  /** @example "2024-05-27T06:50:20.056549Z" */
  created_at?: string;
  /** @example "e31ab6f8-d359-4c6a-83c6-bfa32229bb01" */
  created_by?: string;
  /** @example "SUCCESSFUL" */
  delivery_status?: 'PENDING' | 'SUCCESSFUL' | 'FAILED' | 'NOT_APPLICABLE';
  /** @example "25-05-2024" */
  end_date?: string;
  /** @example "TD1_006800_20230528_L2A_20230615_03001067" */
  gss_task_id?: string;
  /** @example "47435e2b-d8c4-41ff-9de9-2be3bfc92276" */
  id?: string;
  /** @example 4 */
  number_of_strips?: number;
  /** @example "TD1_006800_20230528_L2A_20230615_03001067" */
  order_item_id?: string;
  /** @example "15-05-2024" */
  start_date?: string;
  /** @example "COMPLETED" */
  status?: ConstantTaskStatus;
  /** @example 10 */
  tokens_used?: number;
  /** @example "47435e2b-d8c4-41ff-9de9-2be3bfc92276" */
  transaction_id?: string;
  /** @example "ARCHIVE" */
  type?: ConstantTaskType;
  /** @example "2024-05-27T06:50:20.056549Z" */
  updated_at?: string;
  /** @example "e31ab6f8-d359-4c6a-83c6-bfa32229bb01" */
  updated_by?: string;
}

export interface TaskStripRequest {
  /** @example "37435e2b-d8c4-41ff-9de9-2be3bfc92276" */
  id: string;
}

export interface TaskUpdateReadResponse {
  /** @example "2024-05-27T06:50:20.056549Z" */
  created_at?: string;
  /** @example "e31ab6f8-d359-4c6a-83c6-bfa32229bb01" */
  created_by?: string;
  /** @example "SUCCESSFUL" */
  delivery_status?: 'PENDING' | 'SUCCESSFUL' | 'FAILED' | 'NOT_APPLICABLE';
  /** @example "25-05-2024" */
  end_date?: string;
  /** @example "TD1_006800_20230528_L2A_20230615_03001067" */
  gss_task_id?: string;
  /** @example "47435e2b-d8c4-41ff-9de9-2be3bfc92276" */
  id?: string;
  /** @example 4 */
  number_of_strips?: number;
  /** @example "TD1_006800_20230528_L2A_20230615_03001067" */
  order_item_id?: string;
  /** @example "15-05-2024" */
  start_date?: string;
  /** @example "COMPLETED" */
  status?: ConstantTaskStatus;
  strips?: StripReadResponse[];
  /** @example 10 */
  tokens_used?: number;
  /** @example "47435e2b-d8c4-41ff-9de9-2be3bfc92276" */
  transaction_id?: string;
  /** @example "ARCHIVE" */
  type?: ConstantTaskType;
  /** @example "2024-05-27T06:50:20.056549Z" */
  updated_at?: string;
  /** @example "e31ab6f8-d359-4c6a-83c6-bfa32229bb01" */
  updated_by?: string;
}

export interface TaskUpdateRequest {
  /** @example "37435e2b-d8c4-41ff-9de9-2be3bfc92276" */
  gss_task_id: string;
  strips: TaskStripRequest[];
}

export interface TaskUpdateTaskRequest {
  /** @example "DELIVERED" */
  delivery_status?: ConstantDeliveryStatus;
  /** @example "2021-08-01T00:00:00Z" */
  end_date?: string;
  /** @example "2021-08-01T00:00:00Z" */
  start_date?: string;
  /** @example "IN_PROGRESS" */
  status?: ConstantTaskStatus;
}

export interface TraceReadListResponse {
  traces?: TraceReadResponse[];
}

export interface TraceReadResponse {
  /** @example "2024-05-27T06:50:20.056549Z" */
  created_at?: string;
  /** @example "e31ab6f8-d359-4c6a-83c6-bfa32229bb01" */
  created_by?: string;
  /** @example 1 */
  id?: number;
  /** @example "47435e2b-d8c4-41ff-9de9-2be3bfc92276" */
  resource_id?: string;
  /** @example "SCHEDULED" */
  status?: ConstantTraceStatus;
  /** @example "order_item" */
  type?: ConstantTraceType;
  /** @example "2024-05-27T06:50:20.056549Z" */
  updated_at?: string;
  /** @example "e31ab6f8-d359-4c6a-83c6-bfa32229bb01" */
  updated_by?: string;
}

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<
  FullRequestParams,
  'body' | 'method' | 'query' | 'path'
>;

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain'
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({
    securityWorker,
    secure,
    format,
    ...axiosConfig
  }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || ''
    });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig
  ): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method &&
          this.instance.defaults.headers[
            method.toLowerCase() as keyof HeadersDefaults
          ]) ||
          {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {})
      }
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    if (input instanceof FormData) {
      return input;
    }
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] =
        property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(
          key,
          isFileType ? formItem : this.stringifyFormItem(formItem)
        );
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (
      type === ContentType.FormData &&
      body &&
      body !== null &&
      typeof body === 'object'
    ) {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (
      type === ContentType.Text &&
      body &&
      body !== null &&
      typeof body !== 'string'
    ) {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type ? { 'Content-Type': type } : {})
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path
    });
  };
}

/**
 * @title Clerk API
 * @version 0.0.1
 * @contact
 */
export class Api<
  SecurityDataType extends unknown
> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags Bandset
     * @name V1BandsetsList
     * @summary List all bandsets
     * @request GET:/api/v1/bandsets
     */
    v1BandsetsList: (params: RequestParams = {}) =>
      this.request<BandsetReadListResponse, ApiErrorResponse>({
        path: `/api/v1/bandsets`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Bandset
     * @name V1BandsetsDetail
     * @summary Get a bandset
     * @request GET:/api/v1/bandsets/{id}
     */
    v1BandsetsDetail: (id: string, params: RequestParams = {}) =>
      this.request<BandsetReadResponse, ApiErrorResponse>({
        path: `/api/v1/bandsets/${id}`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Catalog
     * @name V1CatalogsDownloadsCreate
     * @summary Create a download request for a catalog
     * @request POST:/api/v1/catalogs/downloads
     */
    v1CatalogsDownloadsCreate: (
      body: CatalogDownloadRequest,
      params: RequestParams = {}
    ) =>
      this.request<CatalogDownloadResponse, ApiErrorResponse>({
        path: `/api/v1/catalogs/downloads`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Catalog
     * @name V1CatalogsDownloadsCreate2
     * @summary Get a download request for a catalog by ID
     * @request POST:/api/v1/catalogs/downloads/{id}
     * @originalName v1CatalogsDownloadsCreate
     * @duplicate
     */
    v1CatalogsDownloadsCreate2: (id: string, params: RequestParams = {}) =>
      this.request<CatalogDownloadResponse, ApiErrorResponse>({
        path: `/api/v1/catalogs/downloads/${id}`,
        method: 'POST',
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Catalog
     * @name V1CatalogsListCreate
     * @summary List all catalogs
     * @request POST:/api/v1/catalogs/list
     */
    v1CatalogsListCreate: (
      catalogFilter: GithubComPixxelhqClerkApiCatalogCatalogFilter,
      params: RequestParams = {}
    ) =>
      this.request<CatalogReadListResponse, ApiErrorResponse>({
        path: `/api/v1/catalogs/list`,
        method: 'POST',
        body: catalogFilter,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Delivery
     * @name V1DeliveriesList
     * @summary List all deliveries
     * @request GET:/api/v1/deliveries
     */
    v1DeliveriesList: (
      query?: {
        /** Delivery ID */
        delivery_id?: string;
        /** Catalog ID */
        catalog_id?: string;
        /** Task ID */
        task_id?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<DeliveryReadListResponse, ApiErrorResponse>({
        path: `/api/v1/deliveries`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Delivery
     * @name V1DeliveriesCreate
     * @summary Create a delivery
     * @request POST:/api/v1/deliveries
     */
    v1DeliveriesCreate: (
      body: DeliveryCreateDeliveryRequest,
      params: RequestParams = {}
    ) =>
      this.request<DeliveryReadListResponse, ApiErrorResponse>({
        path: `/api/v1/deliveries`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Bandset
     * @name V1InternalBandsetsCreate
     * @summary Add a new bandset
     * @request POST:/api/v1/internal/bandsets
     */
    v1InternalBandsetsCreate: (
      body: BandsetRequest,
      params: RequestParams = {}
    ) =>
      this.request<BandsetReadResponse, ApiErrorResponse>({
        path: `/api/v1/internal/bandsets`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Bandset
     * @name V1InternalBandsetsUpdate
     * @summary Update a bandset
     * @request PUT:/api/v1/internal/bandsets/{id}
     */
    v1InternalBandsetsUpdate: (
      id: string,
      body: BandsetRequest,
      params: RequestParams = {}
    ) =>
      this.request<BandsetReadResponse, ApiErrorResponse>({
        path: `/api/v1/internal/bandsets/${id}`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Catalog
     * @name V1InternalCatalogsCreate
     * @summary Add a new catalog
     * @request POST:/api/v1/internal/catalogs
     */
    v1InternalCatalogsCreate: (
      body: CatalogCreateCatalogRequest,
      params: RequestParams = {}
    ) =>
      this.request<CatalogReadListResponse, any>({
        path: `/api/v1/internal/catalogs`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Catalog
     * @name V1InternalCatalogsDelete
     * @summary Delete a catalog
     * @request DELETE:/api/v1/internal/catalogs
     */
    v1InternalCatalogsDelete: (
      query: {
        /** Catalog IDs */
        id: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/v1/internal/catalogs`,
        method: 'DELETE',
        query: query,
        ...params
      }),

    /**
     * No description
     *
     * @tags Delivery
     * @name V1InternalDeliveriesUpdate
     * @summary Update a delivery
     * @request PUT:/api/v1/internal/deliveries/{delivery_id}
     */
    v1InternalDeliveriesUpdate: (
      deliveryId: string,
      body: DeliveryUpdateDeliveryRequest,
      params: RequestParams = {}
    ) =>
      this.request<DeliveryReadResponse, ApiErrorResponse>({
        path: `/api/v1/internal/deliveries/${deliveryId}`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name V1InternalOrganizationsList
     * @summary List all organizations
     * @request GET:/api/v1/internal/organizations
     */
    v1InternalOrganizationsList: (
      query?: {
        /** takes 1 as value by default */
        page_no?: number;
        /** takes 50 as value by default */
        page_size?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<OrganizationReadListResponse, ApiErrorResponse>({
        path: `/api/v1/internal/organizations`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name V1InternalOrganizationsCreate
     * @summary Add a new organization
     * @request POST:/api/v1/internal/organizations
     */
    v1InternalOrganizationsCreate: (
      body: OrganizationCreateRequest,
      params: RequestParams = {}
    ) =>
      this.request<OrganizationReadResponse, ApiErrorResponse>({
        path: `/api/v1/internal/organizations`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name V1InternalOrganizationsUpdate
     * @summary Update and existing organization
     * @request PUT:/api/v1/internal/organizations/{id}
     */
    v1InternalOrganizationsUpdate: (
      id: string,
      body: OrganizationUpdateRequest,
      params: RequestParams = {}
    ) =>
      this.request<OrganizationReadResponse, ApiErrorResponse>({
        path: `/api/v1/internal/organizations/${id}`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Strip
     * @name V1InternalStripsUpdateByImageIdUpdate
     * @summary Update a strip by image ID
     * @request PUT:/api/v1/internal/strips/updateByImageID
     */
    v1InternalStripsUpdateByImageIdUpdate: (
      body: StripUpdateStripByImageIDRequest,
      params: RequestParams = {}
    ) =>
      this.request<StripReadResponse, ApiErrorResponse>({
        path: `/api/v1/internal/strips/updateByImageID`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Strip
     * @name V1InternalStripsUpdate
     * @summary Update a strip
     * @request PUT:/api/v1/internal/strips/{strip_id}
     */
    v1InternalStripsUpdate: (
      stripId: string,
      body: StripUpdateStripRequest,
      params: RequestParams = {}
    ) =>
      this.request<StripReadResponse, ApiErrorResponse>({
        path: `/api/v1/internal/strips/${stripId}`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Task
     * @name V1InternalTasksExpirePartialUpdate
     * @summary Expire tasks
     * @request PATCH:/api/v1/internal/tasks/expire
     */
    v1InternalTasksExpirePartialUpdate: (params: RequestParams = {}) =>
      this.request<void, ApiErrorResponse>({
        path: `/api/v1/internal/tasks/expire`,
        method: 'PATCH',
        ...params
      }),

    /**
     * No description
     *
     * @tags Task
     * @name V1InternalTasksUpdateWithStripsCreate
     * @summary Update a task according to provided payload
     * @request POST:/api/v1/internal/tasks/updateWithStrips
     */
    v1InternalTasksUpdateWithStripsCreate: (
      body: TaskUpdateRequest,
      params: RequestParams = {}
    ) =>
      this.request<TaskUpdateReadResponse, ApiErrorResponse>({
        path: `/api/v1/internal/tasks/updateWithStrips`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Task
     * @name V1InternalTasksUpdate
     * @summary Update a Task
     * @request PUT:/api/v1/internal/tasks/{id}
     */
    v1InternalTasksUpdate: (
      id: string,
      body: TaskUpdateTaskRequest,
      params: RequestParams = {}
    ) =>
      this.request<TaskReadResponse, ApiErrorResponse>({
        path: `/api/v1/internal/tasks/${id}`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags OrderItem
     * @name V1OrderItemsList
     * @summary List all orderItems
     * @request GET:/api/v1/order-items
     */
    v1OrderItemsList: (
      query?: {
        /** User ID */
        user_id?: string;
        /** In Cart */
        in_cart?: string;
        /** Type */
        type?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<OrderitemReadListResponse, ApiErrorResponse>({
        path: `/api/v1/order-items`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags OrderItem
     * @name V1OrderItemsCreate
     * @summary Add a new orderItem
     * @request POST:/api/v1/order-items
     */
    v1OrderItemsCreate: (
      body: OrderitemCreateOrderItemRequest,
      params: RequestParams = {}
    ) =>
      this.request<OrderitemReadResponse, any>({
        path: `/api/v1/order-items`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags OrderItem
     * @name V1OrderItemsDelete
     * @summary Delete a orderItem
     * @request DELETE:/api/v1/order-items
     */
    v1OrderItemsDelete: (
      query: {
        /** OrderItem IDs */
        id: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/v1/order-items`,
        method: 'DELETE',
        query: query,
        ...params
      }),

    /**
     * No description
     *
     * @tags OrderItem
     * @name V1OrderItemsDownloadList
     * @summary download orderitem listing
     * @request GET:/api/v1/order-items/download
     */
    v1OrderItemsDownloadList: (
      query?: {
        /** local timezone of the cient */
        tz?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, ApiErrorResponse>({
        path: `/api/v1/order-items/download`,
        method: 'GET',
        query: query,
        ...params
      }),

    /**
     * No description
     *
     * @tags OrderItem
     * @name V1OrderItemsDetail
     * @summary Get a orderItem
     * @request GET:/api/v1/order-items/{id}
     */
    v1OrderItemsDetail: (id: string, params: RequestParams = {}) =>
      this.request<OrderitemReadResponse, ApiErrorResponse>({
        path: `/api/v1/order-items/${id}`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags OrderItem
     * @name V1OrderItemsUpdate
     * @summary Update a orderItem
     * @request PUT:/api/v1/order-items/{id}
     */
    v1OrderItemsUpdate: (
      id: string,
      body: OrderitemUpdateOrderItemRequest,
      params: RequestParams = {}
    ) =>
      this.request<OrderitemReadResponse, ApiErrorResponse>({
        path: `/api/v1/order-items/${id}`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Order
     * @name V1OrdersList
     * @summary List all orders
     * @request GET:/api/v1/orders
     */
    v1OrdersList: (
      query?: {
        /** User ID */
        user_id?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<OrderReadListResponse, ApiErrorResponse>({
        path: `/api/v1/orders`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Order
     * @name V1OrdersCreate
     * @summary Add a new order
     * @request POST:/api/v1/orders
     */
    v1OrdersCreate: (body: OrderCreateRequest, params: RequestParams = {}) =>
      this.request<OrderReadResponse, ApiErrorResponse>({
        path: `/api/v1/orders`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Order
     * @name V1OrdersDetail
     * @summary Get a order
     * @request GET:/api/v1/orders/{id}
     */
    v1OrdersDetail: (id: string, params: RequestParams = {}) =>
      this.request<OrderReadResponse, ApiErrorResponse>({
        path: `/api/v1/orders/${id}`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Order
     * @name V1OrdersUpdate
     * @summary Update a order
     * @request PUT:/api/v1/orders/{id}
     */
    v1OrdersUpdate: (
      id: string,
      body: OrderUpdateRequest,
      params: RequestParams = {}
    ) =>
      this.request<OrderReadResponse, ApiErrorResponse>({
        path: `/api/v1/orders/${id}`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Permission
     * @name V1PermissionsList
     * @summary List all permissions
     * @request GET:/api/v1/permissions
     */
    v1PermissionsList: (
      query?: {
        /** Org ID */
        org_id?: string;
        /** Image ID */
        image_id?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/v1/permissions`,
        method: 'GET',
        query: query,
        ...params
      }),

    /**
     * No description
     *
     * @tags Permission
     * @name V1PermissionsCreate
     * @summary Create a new permission
     * @request POST:/api/v1/permissions
     */
    v1PermissionsCreate: (
      body: PermissionPermission[],
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/v1/permissions`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags Permission
     * @name V1PermissionsDelete
     * @summary Delete a permission
     * @request DELETE:/api/v1/permissions
     */
    v1PermissionsDelete: (
      query: {
        /** Permission IDs */
        id: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/v1/permissions`,
        method: 'DELETE',
        query: query,
        ...params
      }),

    /**
     * No description
     *
     * @tags Strip
     * @name V1StripsList
     * @summary List all strips
     * @request GET:/api/v1/strips
     */
    v1StripsList: (
      query?: {
        /** Task ID */
        task_id?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<StripReadListResponse, any>({
        path: `/api/v1/strips`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Task
     * @name V1TasksList
     * @summary List all tasks
     * @request GET:/api/v1/tasks
     */
    v1TasksList: (
      query: {
        /** Order Item ID */
        order_item_id: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<TaskReadListResponse, ApiErrorResponse>({
        path: `/api/v1/tasks`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Task
     * @name V1TasksDetail
     * @summary Get a task by ID
     * @request GET:/api/v1/tasks/{id}
     */
    v1TasksDetail: (id: string, params: RequestParams = {}) =>
      this.request<TaskReadResponse, ApiErrorResponse>({
        path: `/api/v1/tasks/${id}`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Trace
     * @name V1TracesList
     * @summary List all traces
     * @request GET:/api/v1/traces
     */
    v1TracesList: (
      query?: {
        /** Resource ID */
        resource_id?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<TraceReadListResponse, any>({
        path: `/api/v1/traces`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      })
  };
}
