import React from 'react';

import { Flex, Text } from '@raystack/apsara';
import moment from 'moment';

import { apiClient } from '@src/clients';
import { DomainsFeedItem } from '@src/clients/beacon/api';
import { ReactComponent as CheckFilled } from '@src/icons/check-filled.svg';
import { ReactComponent as Check } from '@src/icons/check.svg';
import { ReactComponent as CrossFilled } from '@src/icons/cross-filled.svg';

import { getNotificationDetails } from './events';

import styles from './styles.module.css';

interface NotificationItemProps {
  message: DomainsFeedItem;
}

export default function NotificationItem({ message }: NotificationItemProps) {
  const { status, icon: Icon } = getNotificationDetails(message.event || '');

  const msgTime = moment(message?.timestamp).fromNow();

  async function markAsRead() {
    try {
      await apiClient.beacon.apiUserSelfReadCreate(message?.message_id || '');
    } catch (err) {
      console.error(err);
    }
  }

  function onClick() {
    const url = message?.action_url || '';
    if (url) {
      markAsRead();
      window.open(url, '_blank', 'rel=noopener noreferrer');
    }
  }

  function onReadClick(e: React.MouseEvent<HTMLElement>) {
    e.stopPropagation();
    markAsRead();
  }

  const isRead = message.status === 'read';

  return (
    <Flex
      className={styles.noticationItem}
      gap={'medium'}
      onClick={onClick}
      data-test-id='notification-item'
      data-read={isRead}
    >
      <div className={styles.iconWrapper}>
        <Icon className={styles.icon} />
        {status ? (
          status === 'success' ? (
            <CheckFilled
              color='var(--foreground-success)'
              className={styles.statusIcon}
            />
          ) : (
            <CrossFilled
              color='var(--foreground-danger)'
              className={styles.statusIcon}
            />
          )
        ) : null}
      </div>
      <Flex gap={'small'} justify='between' style={{ flex: 1 }}>
        <Flex gap={'small'} direction={'column'}>
          <Text
            size={3}
            dangerouslySetInnerHTML={{ __html: message.message_text || '' }}
          />
          <Text size={2} style={{ color: 'var(--foreground-muted)' }}>
            {msgTime}
          </Text>
        </Flex>
        {isRead ? (
          <span className={styles.readBtn} />
        ) : (
          <Flex
            className={styles.readBtn}
            gap={'extra-small'}
            onClick={onReadClick}
            data-test-id='notification-item-read-btn'
          >
            <Check />
            <Text style={{ color: 'var(--foreground-muted)' }}>Read</Text>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}
