import type { FlagsValueType } from './FlagsProvider';

export const FLAGS = {
  BILLING_TOKEN: 'BILLING_TOKEN',
  THEME_SWITCH: 'THEME_SWITCH',
  NOTIFICATIONS: 'NOTIFICATIONS',
  STAC_TO_COG_IN_VISUALIZATION: 'STAC_TO_COG_IN_VISUALIZATION',
  ORDER_DESK: 'ORDER_DESK',
  CLIENT_TOKEN_CHECK: 'CLIENT_TOKEN_CHECK',
  CHANGE_DETECTION_V3: 'CHANGE_DETECTION_V3'
} as const;

export type FLAGS_KEYS = keyof typeof FLAGS;

export const FLAGS_LIST: {
  name: FLAGS_KEYS;
  defaultValue: FlagsValueType;
}[] = [
  {
    name: FLAGS.BILLING_TOKEN,
    defaultValue: false
  },

  {
    name: FLAGS.THEME_SWITCH,
    defaultValue: false
  },
  {
    name: FLAGS.STAC_TO_COG_IN_VISUALIZATION,
    defaultValue: false
  },
  {
    name: FLAGS.NOTIFICATIONS,
    defaultValue: false
  },
  { name: FLAGS.ORDER_DESK, defaultValue: false },
  {
    name: FLAGS.CLIENT_TOKEN_CHECK,
    defaultValue: false
  },
  {
    name: FLAGS.CHANGE_DETECTION_V3,
    defaultValue: false
  }
];
