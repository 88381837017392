import { Suspense, lazy, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
  useParams
} from 'react-router-dom';

import { ToastContainer } from '@raystack/apsara';
import { detect } from 'detect-browser';
import { useAnalytics } from 'use-analytics';

import AppLoader from './components/AppLoader';
import { LoginRoute } from './components/LoginRoute';
import { OnboardingRoute } from './components/OnboardingRoute';
import { PrivateRoute } from './components/PrivateRoute';
import OrgsList from './containers/Auth/OrgsList';
import { ViewerProvider } from './contexts/AOIRasterContext';
import { IdentityContextProvider } from './contexts/identity';
import { useClearLocalStorage } from './hooks/useClearLocalStorage';
import { updateTileRenderFormat } from './redux/_reducers/sourceActionReducer';
import { lightTheme } from './stitches.config';

import './App.css';

// lazy loading components
const ExploreLayout = lazy(() => import('./containers/Explore/Layout/Layout'));
const Viewer = lazy(() => import('./containers/Viewer/Viewer'));
const AOIs = lazy(() => import('./components/AoiPanel/NewAois/NewAois'));
const MarketPlace = lazy(() => import('./containers/MarketPlace/MarketPlace'));
const MarketPlaceList = lazy(
  () => import('./containers/MarketPlace/MarketPlaceList/MarketPlaceList')
);
const ModelDetails = lazy(
  () => import('./containers/MarketPlace/ModelDetails/ModelDetails')
);
const WorkflowList = lazy(
  () => import('./containers/Workflow/WorkFlowListing')
);
const LabWrapper = lazy(() => import('./containers/Workflow/Lab/LabWrapper'));

const Editor = lazy(
  () => import('./containers/Workflow/Lab/Pages/Editor/Editor')
);
const Jobs = lazy(() => import('./containers/Workflow/Lab/Pages/Jobs/Jobs'));

const SignupV2 = lazy(() => import('./containers/Auth/SignUp'));

const MagicLinkVerify = lazy(() => import('./containers/Auth/MagicLinkVerify'));

const LoginV2 = lazy(() => import('./containers/Auth/Login'));

const Callback = lazy(() => import('./containers/Auth/Callback'));

const NewOrganisation = lazy(() => import('./containers/Auth/NewOrg'));

const FreeTrialPage = lazy(() => import('./containers/Auth/FreeTrial'));

const PageNotFound = lazy(() => import('./components/Errors/PageNotFound'));

const PaymentSuccessCallback = lazy(
  () => import('./containers/Payment/success-callback')
);

const CancelCallbackPage = lazy(
  () => import('./containers/Payment/cancel-callback')
);
const OrderDesk = lazy(() => import('./containers/OrderDesk/OrderDesk'));

function RedirectToWorkflows() {
  const { orgSlug } = useParams();

  return <Navigate to={`/${orgSlug}/workflows`} replace />;
}

function RedirectToLogin() {
  const { search } = useLocation();
  return <Navigate to={{ pathname: '/login', search }} />;
}

function AppRoutes() {
  useClearLocalStorage();

  const location = useLocation();
  const analytics = useAnalytics();

  useEffect(() => {
    analytics.page();
  }, [analytics, location]);

  return (
    <Routes>
      <Route path='/' element={<RedirectToLogin />} />

      {/* TODO: temporary redirect as old user will have /explore cached */}
      <Route
        path='/explore'
        element={
          <Suspense fallback={<AppLoader />}>
            <Navigate to='/organizations' />
          </Suspense>
        }
      />

      <Route
        path='/magiclink-verify'
        element={
          <Suspense fallback={<AppLoader />}>
            <LoginRoute component={MagicLinkVerify} />
          </Suspense>
        }
      />

      <Route
        path='/callback'
        element={
          <Suspense fallback={<AppLoader />}>
            <LoginRoute component={Callback} />
          </Suspense>
        }
      />

      {/* Login */}
      <Route
        path='/login'
        element={
          <Suspense fallback={<AppLoader />}>
            <LoginRoute component={LoginV2} />
          </Suspense>
        }
      />

      {/* Sign up */}
      <Route
        path='/signup'
        element={
          <Suspense fallback={<AppLoader />}>
            <LoginRoute component={SignupV2} />
          </Suspense>
        }
      />

      {/* Organization onboarding routes */}
      <Route
        path='/organizations'
        element={
          <Suspense fallback={<AppLoader />}>
            <OnboardingRoute />
          </Suspense>
        }
      >
        <Route
          path=''
          element={
            <Suspense fallback={<AppLoader />}>
              <OrgsList />
            </Suspense>
          }
        />
        <Route
          path='new'
          element={
            <Suspense fallback={<AppLoader />}>
              <NewOrganisation />
            </Suspense>
          }
        />
        <Route path=':orgId' element={<></>} />
        <Route
          path=':orgId/free-trial'
          element={
            <Suspense fallback={<AppLoader />}>
              <FreeTrialPage />
            </Suspense>
          }
        />
      </Route>

      <Route
        path='/payment/success'
        element={
          <Suspense fallback={<AppLoader />}>
            <PaymentSuccessCallback />
          </Suspense>
        }
      ></Route>

      <Route
        path='/payment/cancel'
        element={
          <Suspense fallback={<AppLoader />}>
            <CancelCallbackPage />
          </Suspense>
        }
      ></Route>

      <Route path='/:orgSlug' element={<IdentityContextProvider />}>
        {/* Explore */}
        <Route
          path=''
          element={
            <Suspense fallback={<AppLoader />}>
              <PrivateRoute component={ExploreLayout} />
            </Suspense>
          }
        ></Route>

        {/* Order Desk */}
        <Route
          path='order-desk'
          element={
            <Suspense fallback={<AppLoader />}>
              <PrivateRoute component={OrderDesk} />
            </Suspense>
          }
        />

        {/* Aois */}
        <Route path='aois'>
          {/* Aois List */}
          <Route
            path=''
            element={
              <Suspense fallback={<AppLoader />}>
                <PrivateRoute component={AOIs} />
              </Suspense>
            }
          />

          {/* viewer */}
          <Route
            path=':aoi_id'
            element={
              <Suspense fallback={<AppLoader />}>
                <ViewerProvider>
                  <PrivateRoute component={Viewer} />
                </ViewerProvider>
              </Suspense>
            }
          />
        </Route>

        {/* Marketplace */}
        <Route
          path='marketplace'
          element={
            <Suspense fallback={<AppLoader />}>
              <PrivateRoute component={MarketPlace} />
            </Suspense>
          }
        >
          <Route index element={<MarketPlaceList />} />
          <Route path='modeldetails' element={<ModelDetails />} />
        </Route>

        {/* Workflows */}
        <Route path='workflows'>
          {/* Workflows List */}
          <Route
            path=''
            element={
              <Suspense fallback={<AppLoader />}>
                <PrivateRoute component={WorkflowList} />
              </Suspense>
            }
          />

          <Route
            path=':workflow_id'
            element={
              <Suspense fallback={<AppLoader />}>
                <PrivateRoute component={LabWrapper} />
              </Suspense>
            }
          >
            <Route path='' element={<Editor />} />
            <Route path='jobs' element={<Jobs />} />
            <Route path='jobs/:job_id' element={<Jobs />} />
          </Route>
        </Route>

        {/* TODO: temporary redirect users to new workflows url */}
        <Route path='workflow/*' element={<RedirectToWorkflows />} />
      </Route>
      <Route
        path='*'
        element={
          <Suspense fallback={<AppLoader />}>
            <PageNotFound />
          </Suspense>
        }
      />
    </Routes>
  );
}

function App() {
  const currentTheme = useSelector(state => state.sourceActions.theme);
  const dispatch = useDispatch();

  // check user agent
  useEffect(() => {
    const browser = detect();

    // usage for ie string based on package here:- https://github.com/DamonOehlman/detect-browser/blob/546e6f1348375d8a486f21da07b20717267f6c49/src/index.ts#L88
    if (browser && browser?.name === 'ie') {
      dispatch(updateTileRenderFormat('png'));
      return;
    }

    // default to webp
    dispatch(updateTileRenderFormat('webp'));
    return;
  }, [dispatch]);

  return (
    <div className='container'>
      <div className={currentTheme === 'light' ? lightTheme : null}>
        <Router>
          <AppRoutes />
        </Router>

        <ToastContainer richColors closeButton />
      </div>
    </div>
  );
}
export default App;
